import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.css";
import "./styles/faqs.css";
import "./styles/admin.css";
import "./styles/layout.css";
import "./styles/nav.css";
import "./styles/home.css";
import "./styles/forms.css";
import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Busy from "./components/global/Busy";
const TwilioCall = lazy(()=> import("./components/employer/TwilioCall"))
const AdminDashBoard = lazy(() => import("./components/admin/AdminDashBoard"));
const UpdateWriterProfile = lazy(() =>
  import("./components/writer/UpdateWriterProfile")
);
const UpdatePhoneNumber = lazy(() =>
  import("./components/writer/UpdatePhoneNumber")
);
const Disputes = lazy(() => import("./components/admin/Disputes"));
const Resolved = lazy(() => import("./components/admin/Resolved"));
const EscalatedProjects = lazy(() =>
  import("./components/admin/EscalatedProjects")
);
const AllEmployers = lazy(() => import("./components/admin/AllEmployers"));
const AllWriters = lazy(() => import("./components/admin/AllWriters"));
const AdminChat = lazy(() => import("./components/admin/AdminChat"));
const DisputeResolution = lazy(() =>
  import("./components/admin/DisputeResolution")
);
const MarkedComplete = lazy(() => import("./components/admin/MarkedComplete"));
const AdminProfile = lazy(() => import("./components/admin/AdminProfile"));
const Transactions = lazy(() => import("./components/admin/Transactions"));
const AdminSendNotifications = lazy(() =>
  import("./components/admin/AdminSendNotifications")
);

const AiAssist = lazy(() => import("./components/AiAssist"));
const AiDetect = lazy(() => import("./components/AiDetect"));
const Similarity = lazy(() => import("./components/Similarity"));
const DocumentView = lazy(() => import("./components/DocumentView"));

const EmployerDashboard = lazy(() =>
  import("./components/employer/exporter").then((module) => ({
    default: module.EmployerDashboard,
  }))
);
const Postprojects = lazy(() =>
  import("./components/employer/exporter").then((module) => ({
    default: module.Postprojects,
  }))
);
const Freelancers = lazy(() =>
  import("./components/employer/exporter").then((module) => ({
    default: module.Freelancers,
  }))
);
const EmployerChat = lazy(() =>
  import("./components/employer/exporter").then((module) => ({
    default: module.EmployerChat,
  }))
);
const Bids = lazy(() =>
  import("./components/employer/exporter").then((module) => ({
    default: module.Bids,
  }))
);
const Profile = lazy(() =>
  import("./components/employer/exporter").then((module) => ({
    default: module.Profile,
  }))
);
const Wallet = lazy(() =>
  import("./components/employer/exporter").then((module) => ({
    default: module.Wallet,
  }))
);
const Unassigned = lazy(() =>
  import("./components/employer/exporter").then((module) => ({
    default: module.Unassigned,
  }))
);
const EditProject = lazy(() =>
  import("./components/employer/exporter").then((module) => ({
    default: module.EditProject,
  }))
);
const Notifications = lazy(() =>
  import("./components/employer/exporter").then((module) => ({
    default: module.Notifications,
  }))
);
const WriterInfo = lazy(() =>
  import("./components/employer/exporter").then((module) => ({
    default: module.WriterInfo,
  }))
);
const EmployerFaqs = lazy(() =>
  import("./components/employer/exporter").then((module) => ({
    default: module.EmployerFaqs,
  }))
);
const Writers = lazy(() =>
  import("./components/employer/exporter").then((module) => ({
    default: module.Writers,
  }))
);

const EmployerRevisionInstructions = lazy(() =>
  import("./components/employer/exporter").then((module) => ({
    default: module.EmployerRevisionInstructions,
  }))
);
const Disputed = lazy(() =>
  import("./components/employer/exporter").then((module) => ({
    default: module.Disputed,
  }))
);
const InRevision = lazy(() =>
  import("./components/employer/exporter").then((module) => ({
    default: module.InRevision,
  }))
);
const Completed = lazy(() =>
  import("./components/employer/exporter").then((module) => ({
    default: module.Completed,
  }))
);
const Inprogress = lazy(() =>
  import("./components/employer/exporter").then((module) => ({
    default: module.Inprogress,
  }))
);
const MarkComplete = lazy(() =>
  import("./components/employer/exporter").then((module) => ({
    default: module.MarkComplete,
  }))
);
const MakeDispute = lazy(() =>
  import("./components/employer/exporter").then((module) => ({
    default: module.MakeDispute,
  }))
);
const RequestReview = lazy(() =>
  import("./components/employer/exporter").then((module) => ({
    default: module.RequestReview,
  }))
);
const UpdateEmployerPhoneNo = lazy(() =>
  import("./components/employer/exporter").then((module) => ({
    default: module.UpdateEmployerPhoneNo,
  }))
);
const BidAward = lazy(() =>
  import("./components/employer/exporter").then((module) => ({
    default: module.BidAward,
  }))
);
const DisputeView = lazy(() =>
  import("./components/employer/exporter").then((module) => ({
    default: module.DisputeView,
  }))
);
const EmployerResolved = lazy(() =>
  import("./components/employer/exporter").then((module) => ({
    default: module.EmployerResolved,
  }))
);


const WriterBids = lazy(() =>
  import("./components/writer/writerexporter").then((module) => ({
    default: module.WriterBids,
  }))
);
const WriterAssigned = lazy(() =>
  import("./components/writer/writerexporter").then((module) => ({
    default: module.WriterAssigned,
  }))
);
const WriterProfile = lazy(() =>
  import("./components/writer/writerexporter").then((module) => ({
    default: module.WriterProfile,
  }))
);
const WriterProjects = lazy(() =>
  import("./components/writer/writerexporter").then((module) => ({
    default: module.WriterProjects,
  }))
);
const WriterWallet = lazy(() =>
  import("./components/writer/writerexporter").then((module) => ({
    default: module.WriterWallet,
  }))
);
const WriterDashboard = lazy(() =>
  import("./components/writer/writerexporter").then((module) => ({
    default: module.WriterDashboard,
  }))
);
const PlaceBid = lazy(() =>
  import("./components/writer/writerexporter").then((module) => ({
    default: module.PlaceBid,
  }))
);
const WriterCompleted = lazy(() =>
  import("./components/writer/writerexporter").then((module) => ({
    default: module.WriterCompleted,
  }))
);
const WriterDisputed = lazy(() =>
  import("./components/writer/writerexporter").then((module) => ({
    default: module.WriterDisputed,
  }))
);
const CompletedUpload = lazy(() =>
  import("./components/writer/writerexporter").then((module) => ({
    default: module.CompletedUpload,
  }))
);
const ForRevision = lazy(() =>
  import("./components/writer/writerexporter").then((module) => ({
    default: module.ForRevision,
  }))
);
const RevisionInstructions = lazy(() =>
  import("./components/writer/writerexporter").then((module) => ({
    default: module.RevisionInstructions,
  }))
);
const ViewDispute = lazy(() =>
  import("./components/writer/writerexporter").then((module) => ({
    default: module.ViewDispute,
  }))
);
const Escalate = lazy(() =>
  import("./components/writer/writerexporter").then((module) => ({
    default: module.Escalate,
  }))
);
const WriterResolved = lazy(() =>
  import("./components/writer/writerexporter").then((module) => ({
    default: module.WriterResolved,
  }))
);
const EditBid = lazy(() =>
  import("./components/writer/writerexporter").then((module) => ({
    default: module.EditBid,
  }))
);
const WriterChat = lazy(() =>
  import("./components/writer/writerexporter").then((module) => ({
    default: module.WriterChat,
  }))
);
const WriterNotifications = lazy(() =>
  import("./components/writer/writerexporter").then((module) => ({
    default: module.WriterNotifications,
  }))
);
const WriterFaqs = lazy(() =>
  import("./components/writer/writerexporter").then((module) => ({
    default: module.WriterFaqs,
  }))
);
const Employers = lazy(() =>
  import("./components/writer/writerexporter").then((module) => ({
    default: module.Employers,
  }))
);

const Faqs = lazy(() => import("./components/Faqs"));
const PasswordEmailSent = lazy(() => import("./forms/PasswordEmailSent"));
const VerifyEmail = lazy(() => import("./forms/VerifyEmail"));
const Activate = lazy(() => import("./forms/Activate"));
const Nopagefound = lazy(() => import("./pages/Nopagefound"));
const PrivacyPolicy = lazy(() => import("./components/PrivacyPolicy"));
const TermsOfService = lazy(() => import("./components/TermsOfService"));
const Layout = lazy(() => import("./components/Layout")); 
const Home = lazy(() => import("./pages/Home"));
const Register = lazy(() => import("./forms/Register"));
const Signup = lazy(() => import("./forms/Signup"));
const Login = lazy(() => import("./forms/Login"));
const ResetPassword = lazy(() => import("./forms/ResetPassword"));
const ResetPasswordConfirm = lazy(() => import("./forms/ResetPasswordConfirm"));
const WriterMainSideBar = lazy(() => import("./components/WriterMainSideBar"));
const EmployerMainSideBar = lazy(() =>
  import("./components/EmployerMainSideBar")
);
const AdminMainSidebar = lazy(() =>
  import("./components/admin/AdminMainSidebar")
);





function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<Busy />}>
              <Layout />
            </Suspense>
          }>
          <Route
            index
            element={
              <Suspense fallback={<Busy />}>
                <Home />
              </Suspense>
            }
          />
          <Route
            path="writer"
            element={
              <Suspense fallback={<Busy />}>
                <WriterMainSideBar />
              </Suspense>
            }>
            <Route index element={<WriterDashboard />} />

            <Route
              path="home"
              element={
                <Suspense fallback={<Busy />}>
                  <WriterDashboard />
                </Suspense>
              }
            />
            <Route
              path="projects"
              element={
                <Suspense fallback={<Busy />}>
                  <WriterProjects />
                </Suspense>
              }
            />
            <Route
              path="my_projects"
              element={
                <Suspense fallback={<Busy />}>
                  <WriterAssigned />
                </Suspense>
              }
            />
            <Route
              path="disputed"
              element={
                <Suspense fallback={<Busy />}>
                  <WriterDisputed />
                </Suspense>
              }
            />
            <Route
              path="forrevision"
              element={
                <Suspense fallback={<Busy />}>
                  <ForRevision />
                </Suspense>
              }
            />
            <Route
              path="revisioninstructions"
              element={
                <Suspense fallback={<Busy />}>
                  <RevisionInstructions />
                </Suspense>
              }
            />
            <Route
              path="completed"
              element={
                <Suspense fallback={<Busy />}>
                  <WriterCompleted />
                </Suspense>
              }
            />
            <Route
              path="bids"
              element={
                <Suspense fallback={<Busy />}>
                  <WriterBids />
                </Suspense>
              }
            />
            <Route
              path="bids/editbid"
              element={
                <Suspense fallback={<Busy />}>
                  <EditBid />
                </Suspense>
              }
            />
            <Route
              path="chat"
              element={
                <Suspense fallback={<Busy />}>
                  <WriterChat />
                </Suspense>
              }
            />
            <Route
              path="transactions"
              element={
                <Suspense fallback={<Busy />}>
                  <WriterWallet />
                </Suspense>
              }
            />
            <Route
              path="profile"
              element={
                <Suspense fallback={<Busy />}>
                  <WriterProfile />
                </Suspense>
              }
            />
            <Route
              path="placebid"
              element={
                <Suspense fallback={<Busy />}>
                  <PlaceBid />
                </Suspense>
              }
            />
            <Route
              path="updateprofile"
              element={
                <Suspense fallback={<Busy />}>
                  <UpdateWriterProfile />
                </Suspense>
              }
            />
            <Route
              path="updatephoneno"
              element={
                <Suspense fallback={<Busy />}>
                  <UpdatePhoneNumber />
                </Suspense>
              }
            />
            <Route
              path="completedupload"
              element={
                <Suspense fallback={<Busy />}>
                  <CompletedUpload />
                </Suspense>
              }
            />
            <Route
              path="viewdispute"
              element={
                <Suspense fallback={<Busy />}>
                  <ViewDispute />
                </Suspense>
              }
            />
            <Route
              path="escalate"
              element={
                <Suspense fallback={<Busy />}>
                  <Escalate />
                </Suspense>
              }
            />
            <Route
              path="resolved"
              element={
                <Suspense fallback={<Busy />}>
                  <WriterResolved />
                </Suspense>
              }
            />
            <Route
              path="notifications"
              element={
                <Suspense fallback={<Busy />}>
                  <WriterNotifications />
                </Suspense>
              }
            />
            <Route
              path="in-faqs"
              element={
                <Suspense fallback={<Busy />}>
                  <WriterFaqs />
                </Suspense>
              }
            />
            <Route
              path="my-employers"
              element={
                <Suspense fallback={<Busy />}>
                  <Employers />
                </Suspense>
              }
            />
            <Route
              path="ai_assist"
              element={
                <Suspense fallback={<Busy />}>
                  <AiAssist />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="employer"
            element={
              <Suspense fallback={<Busy />}>
                <EmployerMainSideBar />
              </Suspense>
            }>
            <Route index element={<EmployerDashboard />} />
            <Route
              path="home"
              element={
                <Suspense fallback={<Busy />}>
                  <EmployerDashboard />
                </Suspense>
              }
            />
            <Route
              path="post_projects"
              element={
                <Suspense fallback={<Busy />}>
                  <Postprojects />
                </Suspense>
              }
            />
            <Route
              path="freelancers"
              element={
                <Suspense fallback={<Busy />}>
                  <Freelancers />
                </Suspense>
              }
            />
            <Route
              path="chat"
              element={
                <Suspense fallback={<Busy />}>
                  <EmployerChat />
                </Suspense>
              }
            />
            <Route
              path="bids"
              element={
                <Suspense fallback={<Busy />}>
                  <Bids />
                </Suspense>
              }
            />
            <Route
              path="profile"
              element={
                <Suspense fallback={<Busy />}>
                  <Profile />
                </Suspense>
              }
            />
            <Route
              path="transactions"
              element={
                <Suspense fallback={<Busy />}>
                  <Wallet />
                </Suspense>
              }
            />
            <Route
              path="transactions/:session_id"
              element={
                <Suspense fallback={<Busy />}>
                  <Wallet />
                </Suspense>
              }
            />
            <Route
              path="transactions/:OrderTrackingId/:OrderMerchantReference"
              element={
                <Suspense fallback={<Busy />}>
                  <Wallet />
                </Suspense>
              }
            />
            <Route
              path="my_projects"
              element={
                <Suspense fallback={<Busy />}>
                  <Unassigned />
                </Suspense>
              }
            />
            <Route
              path="editproject"
              element={
                <Suspense fallback={<Busy />}>
                  <EditProject />
                </Suspense>
              }
            />
            <Route
              path="notifications"
              element={
                <Suspense fallback={<Busy />}>
                  <Notifications />
                </Suspense>
              }
            />
            <Route
              path="writer-info"
              element={
                <Suspense fallback={<Busy />}>
                  <WriterInfo />
                </Suspense>
              }
            />
            <Route
              path="faqs"
              element={
                <Suspense fallback={<Busy />}>
                  <EmployerFaqs />
                </Suspense>
              }
            />
            <Route 
              path="call"
              element={
              <Suspense fallback={<Busy/>}><TwilioCall/></Suspense>
            }/>
            <Route
              path="my_writers"
              element={
                <Suspense fallback={<Busy />}>
                  <Writers />
                </Suspense>
              }
            />
            <Route
              path="ai_assist"
              element={
                <Suspense fallback={<Busy />}>
                  <AiAssist />
                </Suspense>
              }
            />
            <Route
              path="ai_detect"
              element={
                <Suspense fallback={<Busy />}>
                  <AiDetect />
                </Suspense>
              }
            />
            <Route
              path="similarity"
              element={
                <Suspense fallback={<Busy />}>
                  <Similarity />
                </Suspense>
              }
            />
            <Route
              path="ai_check_view"
              element={
                <Suspense fallback={<Busy />}>
                  <DocumentView />
                </Suspense>
              }
            />
            <Route
              path="revisioninstructions"
              element={
                <Suspense fallback={<Busy />}>
                  <EmployerRevisionInstructions />
                </Suspense>
              }
            />
            <Route
              path="disputed"
              element={
                <Suspense fallback={<Busy />}>
                  <Disputed />
                </Suspense>
              }
            />
            <Route
              path="inrevision"
              element={
                <Suspense fallback={<Busy />}>
                  <InRevision />
                </Suspense>
              }
            />
            <Route
              path="completed"
              element={
                <Suspense fallback={<Busy />}>
                  <Completed />
                </Suspense>
              }
            />
            <Route
              path="inprogress"
              element={
                <Suspense fallback={<Busy />}>
                  <Inprogress />
                </Suspense>
              }
            />
            <Route
              path="complete"
              element={
                <Suspense fallback={<Busy />}>
                  <MarkComplete />
                </Suspense>
              }
            />
            <Route
              path="dispute"
              element={
                <Suspense fallback={<Busy />}>
                  <MakeDispute />
                </Suspense>
              }
            />
            <Route
              path="requestrevision"
              element={
                <Suspense fallback={<Busy />}>
                  <RequestReview />
                </Suspense>
              }
            />
            <Route
              path="updatephoneno"
              element={
                <Suspense fallback={<Busy />}>
                  <UpdateEmployerPhoneNo />
                </Suspense>
              }
            />
            <Route
              path="awardbid"
              element={
                <Suspense fallback={<Busy />}>
                  <BidAward />
                </Suspense>
              }
            />
            <Route
              path="disputeview"
              element={
                <Suspense fallback={<Busy />}>
                  <DisputeView />
                </Suspense>
              }
            />
            <Route
              path="resolved"
              element={
                <Suspense fallback={<Busy />}>
                  <EmployerResolved />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="siteadmin"
            element={
              <Suspense fallback={<Busy />}>
                <AdminMainSidebar />
              </Suspense>
            }>
            <Route
              index
              element={
                <Suspense fallback={<Busy />}>
                  <AdminDashBoard />
                </Suspense>
              }
            />
            <Route
              path="disputes"
              element={
                <Suspense fallback={<Busy />}>
                  <Disputes />
                </Suspense>
              }
            />
            <Route
              path="resolved"
              element={
                <Suspense fallback={<Busy />}>
                  <Resolved />
                </Suspense>
              }
            />
            <Route
              path="escalated"
              element={
                <Suspense fallback={<Busy />}>
                  <EscalatedProjects />
                </Suspense>
              }
            />
            <Route
              path="chat"
              element={
                <Suspense fallback={<Busy />}>
                  <AdminChat />
                </Suspense>
              }
            />
            <Route
              path="employers"
              element={
                <Suspense fallback={<Busy />}>
                  <AllEmployers />
                </Suspense>
              }
            />
            <Route
              path="freelancers"
              element={
                <Suspense fallback={<Busy />}>
                  <AllWriters />
                </Suspense>
              }
            />
            <Route
              path="disputeresolve"
              element={
                <Suspense fallback={<Busy />}>
                  <DisputeResolution />
                </Suspense>
              }
            />
            <Route
              path="markedcomplete"
              element={
                <Suspense fallback={<Busy />}>
                  <MarkedComplete />
                </Suspense>
              }
            />
            <Route
              path="profile"
              element={
                <Suspense fallback={<Busy />}>
                  <AdminProfile />
                </Suspense>
              }
            />
            <Route
              path="transactions"
              element={
                <Suspense fallback={<Busy />}>
                  <Transactions />
                </Suspense>
              }
            />
            <Route
              path="sendnotifications"
              element={
                <Suspense fallback={<Busy />}>
                  <AdminSendNotifications />
                </Suspense>
              }
            />
          </Route>
        </Route>
        <Route
          path="/activate/:uid/:token"
          element={
            <Suspense fallback={<Busy />}>
              <Activate />
            </Suspense>
          }
        />
        <Route
          path="/password/reset/confirm/:uid/:token"
          element={
            <Suspense fallback={<Busy />}>
              <ResetPasswordConfirm />
            </Suspense>
          }
        />
        <Route
          path="/faqs"
          element={
            <Suspense fallback={<Busy />}>
              <Faqs />
            </Suspense>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <Suspense fallback={<Busy />}>
              <PrivacyPolicy />
            </Suspense>
          }
        />
        <Route
          path="/terms-of-service"
          element={
            <Suspense fallback={<Busy />}>
              <TermsOfService />
            </Suspense>
          }
        />
        <Route
          path="/register"
          element={
            <Suspense fallback={<Busy />}>
              <Register />
            </Suspense>
          }>
          <Route
            index
            element={
              <Suspense fallback={<Busy />}>
                <Signup />
              </Suspense>
            }
          />
          <Route
            path="login"
            element={
              <Suspense fallback={<Busy />}>
                <Login />
              </Suspense>
            }
          />
          <Route
            path="change_password"
            element={
              <Suspense fallback={<Busy />}>
                <ResetPassword />
              </Suspense>
            }
          />
          <Route
            path="password_reset_email_sent"
            element={
              <Suspense fallback={<Busy />}>
                <PasswordEmailSent />
              </Suspense>
            }
          />
          <Route
            path="verify_email"
            element={
              <Suspense fallback={<Busy />}>
                <VerifyEmail />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="*"
          element={
            <Suspense fallback={<Busy />}>
              <Nopagefound />
            </Suspense>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
